@import "@angular/material/theming";

:root {
  --primaryBlue: hsla(214, 100%, 54%, 1);
  --primaryBlue-8: hsla(214, 100%, 54%, 0.08);
  --primaryBlue-12: hsla(214, 100%, 54%, 0.12);
  --background: hsla(214, 100%, 99%, 1);
  --green: hsla(151, 73%, 37%, 1);
  --red: hsla(342, 90%, 51%, 1);
  --yellow: hsla(40, 100%, 53%, 1);
  --infoblue: hsla(202, 75%, 56%, 1);
  --lightGreen: hsla(151, 73%, 37%, 0.2);
  --lightRed: hsla(342, 90%, 51%, 0.2);
  --lightyellow: hsla(40, 100%, 53%, 0.2);
  --lightblue: hsla(214, 100%, 54%, 0.12);
  --lightInfoBlue: hsla(40, 100%, 53%, 0.2);
  --fontDarkBlue: hsla(215, 47%, 22%, 1);
  --fontBlack: hsla(211, 29%, 18%, 1);
  --fontBlackAlt: hsla(214, 61%, 19%, 1);
  --fontNavyAlt: hsla(214, 68%, 16%, 1);
  --grey: hsla(218, 20%, 51%, 1);
  --lightGrey: hsla(220, 17%, 93%, 1);
  --grey-main: hsla(214, 34%, 63%, 1);
  --primary-white: hsla(0, 0%, 100%, 1);
  --title-font: hsla(214, 54%, 36%, 1);
  --grey-light: hsla(218, 17%, 71%, 1);
  --body-font: hsla(218, 33%, 30%, 1);
  --grey-lighter: hsla(220, 17%, 93%, 1);
  --border-color: hsla(222, 35%, 81%, 1);
  --table-header-bgcolor: hsla(210, 33%, 96%, 1);
  --table-header-textcolor: hsla(221, 15%, 32%, 1);

  /*======================================*/

  --fancy-violet: hsla(234, 85%, 73%, 1);
  --fancy-green: hsla(137, 48%, 56%, 1);
  --fancy-pink: hsla(342, 100%, 69%, 1);
  --fancy-blue: hsla(214, 100%, 64%, 1);

  /*======================================*/
  --border-color-75: hsla(222, 35%, 81%, 0.75);
}

//Color - font-family - font-weight - font-size

// ==========[Black Font]==============
.blackAlt-rubik-normal-24 {
  color: var(--fontBlackAlt);
  font-weight: 400;
  font-size: var(--font-size-24);
}

.blackAlt-inter-medium-24 {
  font-weight: 500;
  font-size: var(--font-size-24);
  font-family: "Inter";
}

.blackAlt-inter-semibold-18 {
  font-weight: 600;
  font-size: var(--font-size-18);
  font-family: "Inter";
}

.blackAlt-inter-medium-16 {
  font-weight: 500;
  font-size: var(--font-size-16);
  font-family: "Inter";
}

.blackAlt-inter-medium-18 {
  font-weight: 500;
  font-size: var(--font-size-18);
  font-family: "Inter";
}

.blackAlt-inter-normal-16 {
  font-weight: 400;
  font-size: var(--font-size-16);
  font-family: "Inter";
}

.blackAlt50-inter-medium-14 {
  font-family: "Inter";
  font-weight: normal;
  font-size: var(--font-size-14);
  opacity: 0.5;
}

.black-font-inter-medium-12 {
  font-family: "Inter";
  font-weight: normal;
  font-size: var(--font-size-12);
  opacity: 0.5;
  color: var(--fontBlack);
}

.black-font-inter-medium-16 {
  font-family: "Inter";
  font-weight: 600;
  font-size: var(--font-size-16);
  color: var(--fontBlack);
}

.black-font-inter-normal-14 {
  font-family: "Inter";
  color: var(--fontBlack);
  font-weight: 400;
  font-size: var(--font-size-14);
}

.black-font-inter-medium-14 {
  font-family: "Inter";
  color: var(--fontBlack);
  font-weight: 500;
  font-size: var(--font-size-14);
}

.black-font-inter-semi-bold-14 {
  font-family: "Inter";
  color: var(--fontBlack);
  font-weight: 600;
  font-size: var(--font-size-14);
}

.black-font-inter-normal-10 {
  font-family: "Inter";
  color: var(--fontBlack);
  font-weight: 400;
  font-size: 10px;
}

.black-font-inter-bold-14 {
  font-family: "Inter";
  color: var(--fontBlack);
  font-weight: 700;
  font-size: var(--font-size-14);
}

// =====================================

// ==========[Grey Font]==============
.grey-rubik-medium-20 {
  font-weight: 500;
  font-size: var(--font-size-20);
  color: var(--grey);
}

.grey-inter-bold-24 {
  font-family: "Inter";
  font-style: normal;
  font-weight: bold;
  font-size: var(--font-size-24);
  color: var(--grey-main);
}

.greyMain-rubik-normal-18 {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  color: var(--grey-main) !important;
}

.greyMain-rubik-medium-16 {
  font-style: normal;
  font-weight: 500;
  font-size: var(--font-size-16);
  color: var(--grey-main);
}

.greyMain-rubik-medium-18 {
  font-style: normal;
  font-weight: 500;
  font-size: var(--font-size-18);
  color: var(--grey-main);
}

.greyMain-rubik-normal-20 {
  font-style: normal;
  font-weight: 400;
  font-size: var(--font-size-20);
  color: var(--grey-main);
}

.greyMain-rubik-normal-16 {
  font-style: normal;
  font-weight: 400;
  font-size: var(--font-size-16);
  color: var(--grey-main);
}

.greyMain-rubik-normal-14 {
  font-style: normal;
  font-weight: 400;
  font-size: var(--font-size-14);
  color: var(--grey-main);
}

.greyMain-rubik-normal-12 {
  font-style: normal;
  font-weight: 400;
  font-size: var(--font-size-12);
  color: var(--grey-main);
}

.greylight-rubik-normal-16 {
  font-style: normal;
  font-weight: 400;
  font-size: var(--font-size-16);
  color: var(--grey-light);
}

// =====================================

// ==========[White Font]==============
.white-rubik-normal-16 {
  font-weight: normal;
  font-size: var(--font-size-16);
  color: var(--primary-white);
}

.white-rubik-normal-14 {
  font-weight: 500;
  font-size: var(--font-size-14);
  color: var(--primary-white);
}

.white-inter-bold-32 {
  font-family: "Inter";
  font-weight: bold;
  font-size: var(--font-size-32);
  color: var(--primary-white);
}

// =====================================

// ==========[title Font]==============

.titleFont-inter-semiBold-20 {
  font-family: "Inter";
  font-weight: 600;
  font-size: var(--font-size-20);
  color: var(--title-font);
}

.titleFont-rubic-normal-16 {
  font-weight: 400;
  font-size: var(--font-size-16);
  color: var(--title-font);
}

.titleFont-rubic-normal-14 {
  font-weight: normal;
  font-size: var(--font-size-14);
  color: var(--title-font);
  text-transform: capitalize;
}

// =====================================

// ==========[body Font]==============
.bodyFont-inter-semiBold-16 {
  font-family: "Inter";
  font-weight: 600;
  font-size: var(--font-size-16);
  color: var(--body-font);
}

.bodyFont-inter-medium-12 {
  color: var(--body-font);
  font-family: "Inter";
  font-weight: 500;
  font-size: 12;
}

.bodyFont-inter-normal-14 {
  color: var(--body-font);
  font-family: "Inter";
  font-weight: 400;
  font-size: 14;
}

.bodyFont-inter-medium-14 {
  color: var(--body-font);
  font-family: "Inter";
  font-weight: 500;
  font-size: 14;
}

.bodyFont-inter-semiBold-14 {
  color: var(--body-font);
  font-family: "Inter";
  font-weight: 600;
  font-size: 14;
}

// =====================================

// ==========[green Font]==============

.green-rubik-medium-14 {
  color: var(--green);
  font-weight: 500;
  font-size: var(--font-size-14);
}

.green-rubik-normal-14 {
  color: var(--green);
  font-weight: 400;
  font-size: var(--font-size-14);
}

.green-rubik-normal-16 {
  color: var(--green);
  font-weight: 400;
  font-size: var(--font-size-16);
}

.green-rubik-medium-16 {
  color: var(--green);
  font-weight: 500;
  font-size: var(--font-size-16);
}

// =====================================

// ==========[blue Font]==============

.primaryBlue-rubik-normal-16 {
  color: var(--primaryBlue);
  font-weight: 400;
  font-size: var(--font-size-16);
}

.primaryBlue-rubik-medium-14 {
  color: var(--primaryBlue);
  font-weight: 500;
  font-size: var(--font-size-14);
}

.primaryBlue-rubik-medium-16 {
  color: var(--primaryBlue);
  font-weight: 500;
  font-size: var(--font-size-16);
}

// =====================================

.primaryBlue-rubik-normal-16 {
  font-weight: normal;
  font-size: var(--font-size-16);
  color: var(--primaryBlue);
}

.inter-semiBold-14 {
  font-family: "Inter";
  font-weight: 600;
  font-size: var(--font-size-14);
  color: var(--title-font);
}

//======[COLOR SCHEMES - BACKGROUND]===========
//bgcolor- opacity - textcolor

.bg-grey-12-greyMain {
  background-color: hsla(214, 54%, 36%, 0.12);
  color: var(--grey-main);
}

.bg-grey-light-12-greyMain {
  background-color: hsla(218, 17%, 71%, 0.12);
  color: var(--grey-main);
}

.bg-grey-8-greyMain {
  background-color: #e8ebf0;
  color: var(--grey-main);
}

.bg-greyMain-12-bodyFont {
  background-color: hsla(214, 34%, 63%, 0.12);
  color: var(--body-font);
}

.bg-greyMain-12-greyMain {
  background-color: hsla(214, 34%, 63%, 0.12);
  color: var(--grey-main);
}

.bg-greylighter-100-greyMain {
  background-color: var(--grey-lighter);
  color: var(--grey-main);
}

.bg-infoblue-20-infoblue {
  background-color: hsla(202, 75%, 56%, 0.2);
  color: var(--infoblue);
}

.table-header-bgcolor-table-header-textcolor {
  background-color: var(--table-header-bgcolor);
  color: var(--table-header-textcolor);
}

// ============[WIDTH]===============
.min-width-170 {
  min-width: 170px;
}

.width-170 {
  width: 160px;
  height: 42px;
}

.width-52 {
  width: 42px;
  height: 42px;
}

.box-42 {
  width: 42px;
  height: 42px;
}

.box-106 {
  width: 106px;
  height: 42px;
}

.dayHeaderContainer {
  padding-top: 8px !important;
  padding-bottom: 8px !important;

  // border-width: 0px !important;
  a {
    color: var(--grey-main);
  }
}

.eventContainer {
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 6px;
  // border-left: 4px solid var(--primaryBlue) !important;
  margin-left: 4px !important;
  margin-right: 4px !important;
  font-size: 16px !important;
  font-weight: 400;
}

.left0 {
  @extend .eventContainer;
  border-left: 4px solid hsla(214, 100%, 54%, 1) !important;
}

.left1 {
  @extend .eventContainer;
  border-left: 4px solid hsla(151, 73%, 37%, 1) !important;
}

.left2 {
  @extend .eventContainer;
  border-left: 4px solid hsla(342, 90%, 51%, 1) !important;
}

.left3 {
  @extend .eventContainer;
  border-left: 4px solid hsla(40, 100%, 53%, 1) !important;
}

.left4 {
  @extend .eventContainer;
  border-left: 4px solid hsla(202, 75%, 56%, 1) !important;
}

.left5 {
  @extend .eventContainer;
  border-left: 4px solid hsla(211, 29%, 18%, 1) !important;
}

.left6 {
  @extend .eventContainer;
  border-left: 4px solid hsla(234, 85%, 73%, 1) !important;
}

.left7 {
  @extend .eventContainer;
  border-left: 4px solid hsla(342, 100%, 69%, 1) !important;
}

.fc .fc-scrollgrid,
.fc .fc-scrollgrid table {
  width: 100% !important;
}

.fc-daygrid-body.fc-daygrid-body-unbalanced {
  width: 100% !important;
}

.fc-view-harness.fc-view-harness-active {
  height: 600px !important;
}

//======[BUTTON STYLES]===========
.submit-button {
  background-color: var(--primaryBlue);
  color: #fff;
}

.button-fill-green {
  background-color: var(--green);
  color: #fff;
}

.cancel-button {
  background-color: var(--grey-lighter);
  color: var(--grey);
}

.button-fill-blue {
  background-color: var(--primaryBlue);
  color: #fff;
}

.button-fill-red {
  background-color: var(--red);
  color: #fff;
}

.button {
  background-color: var(--grey-lighter);
  color: var(--grey);
}

.syllabus-icon {
  color: var(--primaryBlue);
  font-size: 20px;
}

.syllabus-button {
  color: var(--primaryBlue);
  background-color: #fff;
  border: 2px solid var(--primaryBlue) !important;
  border-radius: 4px;
}

.add--tutor {
  background-color: var(--primaryBlue);
  color: #fff;
  height: 36px;
}

.secondary-btn {
  background-color: var(--lightblue);
  color: var(--primaryBlue) !important;
  height: 36px;
}

.icon-container {
  position: relative;
  height: 36px;
  width: 36px;
  border-radius: 50%;
  border: none;
  display: flex;
  align-content: center;
  justify-content: center;
}

.icon-more {
  width: 21px;
  height: 5px;
}

//======[STATUS INDICATOR STYLES]===========
.status-indicator-green {
  background-color: hsla(122, 93%, 91%, 1);
  color: var(--green);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 6px;
  max-width: 80px;
  border-radius: 4px;
}

.status-indicator-green-approve {
  background-color: hsla(122, 93%, 91%, 1);
  color: var(--green);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 6px;
  max-width: 120px;
  height: 40px;
  border-radius: 20px;
}

.status-indicator-red {
  background-color: hsla(338, 46%, 93%, 1);
  color: var(--red);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 6px;
  max-width: 80px;
  border-radius: 4px;
}

.status-indicator-red-approve {
  background-color: hsla(338, 46%, 93%, 1);
  color: var(--red);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 6px;
  max-width: 120px;
  height: 40px;
  border-radius: 20px;
}

.status-indicator-yellow {
  background-color: var(--lightyellow);
  color: var(--yellow);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 6px;
  max-width: 80px;
  border-radius: 4px;
}

.status-indicator-yellow-approve {
  background-color: var(--lightyellow);
  color: var(--yellow);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 6px;
  max-width: 120px;
  height: 40px;
  border-radius: 20px;
}

//hide nav header
.custom-tab>.mat-tab-header {
  display: none !important;
}

//stepper header
.custom-stepper {
  background-color: transparent;

  div.mat-horizontal-stepper-header-container {
    display: none !important;
  }
}

//stepper content
.mat-horizontal-content-container {
  padding: 0 !important;
}

//date picker icon alignment
mat-datepicker-toggle.datepicker-icon>button {
  transform: translateY(-6px) !important;
}

// common CSS Propery
.cursor-pointer {
  cursor: pointer;
}

// icon Styles
.icon-next-arrow-rotate {
  transform: rotate(180deg);
  display: block;

  svg {
    margin-top: -3px;
  }
}

// ********** ********** //
// Dragula CSS
.gu-mirror {
  position: fixed !important;
  margin: 0 !important;
  z-index: 9999 !important;
  opacity: 0.8;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
  filter: alpha(opacity=80);
}

.gu-hide {
  display: none !important;
}

.gu-unselectable {
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}

.gu-transit {
  opacity: 0.2;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=20)";
  filter: alpha(opacity=20);
}

// **********Scroll Bar ********** //

::-webkit-scrollbar {
  width: 5px;
  height: 5px;

  &:hover {
    width: 8px;
    height: 10px;
  }
}

::-webkit-scrollbar-thumb {
  background-color: rgba(26, 115, 232, 0.5);
}

::-webkit-scrollbar-thumb {
  &:hover {
    background-color: rgba(26, 115, 232, 0.8);
  }
}

//NO DATA MAT ROW
.mat-row .no-results {
  height: 30vh;
  text-align: center;
}

//notif panel tab style
.notif-tab {
  .mat-tab-header {
    top: 0;
    z-index: 999;
    position: sticky;
    position: -webkit-sticky;
    background: #ffffff;
    /* macOS/iOS Safari */
  }

  .mat-tab-label {
    font-size: 14px;
    font-weight: 500;
    color: #2a548c;
    min-width: 50px;
  }
}

//Button Spinner
.mat-progress-spinner.spin-on-instance {
  display: inline-block;

  svg {
    width: 20px !important;
    height: 20px !important;

    &>circle {
      stroke: var(--primaryBlue);
    }
  }
}

//Delete Icon
.delete-icon {
  color: var(--red);
  border-radius: 50%;
  width: 36px;
  height: 36px;
  background-color: var(--lightRed);
  border: none;
  font-size: 24px;
}

//Attendance Marking icon
.attendance-container {
  border-radius: 50%;
  border-width: 2px;
  border-style: solid;
}

/* Shimmer Effect */
.shimmer-card {
  height: 300px;
  width: 300px;
}

.shimmer-shine {
  min-height: 200px;
  min-width: 200px;
  border-radius: var(--BUTTON_COMMON_BORDER_RADIUS);
  background: #f6f7f8;
  background-image: linear-gradient(to right,
      #f6f7f8 0%,
      #edeef1 20%,
      #f6f7f8 40%,
      #f6f7f8 100%);
  background-repeat: no-repeat;
  background-size: 300px 300px;
  display: inline-block;
  position: relative;

  animation: placeholderShimmer 1s linear;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
}

@keyframes placeholderShimmer {
  0% {
    background-position: -468px 0;
  }

  100% {
    background-position: 468px 0;
  }
}

.branch-color {
  color: #3b73c6;
}

.common-card-margin {
  margin: var(--COMMON-CARD-MARGIN);
}

/* DISPLAY POSITIONS */
.custom-display-flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Grid System */

.custom-d-grid-autofit {
  display: flex;
  gap: 3rem;
  justify-content: start;
  margin: 0 4rem;
}

// loading animation
.loading {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading .spinner {
  width: 50px;
  height: 50px;
  border: 5px solid #f3f3f3;
  border-top: 5px solid var(--primaryBlue);
  border-radius: 50%;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

// color classes
.color-primary {
  color: var(--primaryBlue);
}

.color-red {
  color: var(--red);
}

.color-warning-info {
  color: #856404;
}
