@use "@angular/material" as mat;
@import "@angular/material/theming";
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
// Plus imports for other components in your app.
@import "~@angular/material/prebuilt-themes/indigo-pink.css";

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/

$mat-myapp-primary: (
  50: #e3efff,
  100: #b9d7ff,
  200: #8abdff,
  300: #5ba2ff,
  400: #378eff,
  500: #147aff,
  600: #1272ff,
  700: #0e67ff,
  800: #0b5dff,
  900: #064aff,
  A100: #ffffff,
  A200: #f3f6ff,
  A400: #c0ceff,
  A700: #a7baff,
  contrast: (50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);
$md-red: (
  50: #fee3eb,
  100: #fbb8cc,
  200: #f989aa,
  300: #f75988,
  400: #f5366f,
  500: #f31255,
  600: #f1104e,
  700: #ef0d44,
  800: #ed0a3b,
  900: #ea052a,
  A100: #ffffff,
  A200: #ffdfe3,
  A400: #ffacb6,
  A700: #ff929f,
  contrast: (50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

/* For use in src/lib/core/theming/_palette.scss */
$md-green: (
  50: #e3f4ec,
  100: #bae3d0,
  200: #8cd1b0,
  300: #5ebf90,
  400: #3cb179,
  500: #19a361,
  600: #169b59,
  700: #12914f,
  800: #0e8845,
  900: #087733,
  A100: #a7ffc4,
  A200: #74ffa2,
  A400: #41ff80,
  A700: #27ff6f,
  contrast: (50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$TCS-Dept-Portal-primary: mat-palette($mat-myapp-primary);
$TCS-Dept-Portal-accent: mat.define-palette($md-green, 700, 300, 900);
$TCS-Dept-Portal-red: mat.define-palette($md-red);
$custom-typography: mat.define-typography-config($font-family: "Rubik",
  );
@include mat.core($custom-typography);
// The warn palette is optional (defaults to red).
$TCS-Dept-Portal-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$TCS-Dept-Portal-theme: mat.define-light-theme((color: (primary: $TCS-Dept-Portal-primary,
        accent: $TCS-Dept-Portal-accent,
        warn: $TCS-Dept-Portal-red,
      ),
    ));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($TCS-Dept-Portal-theme);

/* You can add global styles to this file, and also import other style files */

// :root {
//   --primary: blue;
// }
*,
*::before,
*::after {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  background: var(--background);
  margin: 0;
  padding: 0;
  min-height: 100vh;
  box-sizing: border-box;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  overflow: hidden auto;
}

@media (max-width: 768px) {
  #backdrop {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    background: black;
    z-index: 999;
    opacity: 0.5;
    display: block;
  }
}

.mainbody {
  padding: 24px;
  background-color: var(--background);
}

.alert-danger {
  background: hsla(342, 90%, 51%, 1) !important;
  color: white;
}

.alert-success {
  background-color: hsla(151, 73%, 37%, 1) !important;
  color: white;
}

.alert-warning {
  background: hsla(40, 100%, 53%, 1) !important;
  color: white;
}

.text-ellipsis-reponsive {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

// =================[ Font-size Scaling]=============================

:root {
  --font-size-12: 12px;
  --font-size-14: 14px;
  --font-size-16: 16px;
  --font-size-18: 18px;
  --font-size-19: 19px;
  --font-size-20: 20px;
  --font-size-24: 24px;
  --font-size-28: 28px;
  --font-size-32: 32px;
}