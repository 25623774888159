.rbk-pB-75-N-14,
.rbk-pB-75-N-16,
.rbk-pB-75-N-18,
.rbk-pB-75-N-20,
.rbk-pB-75-N-24,
.rbk-pB-75-M-14,
.rbk-pB-75-M-16,
.rbk-pB-75-M-18,
.rbk-pB-75-M-20,
.rbk-pB-75-M-24,
.rbk-pB-75-SB-14,
.rbk-pB-75-SB-16,
.rbk-pB-75-SB-18,
.rbk-pB-75-SB-20,
.rbk-pB-75-SB-24,
.rbk-pB-75-B-14,
.rbk-pB-75-B-16,
.rbk-pB-75-B-18,
.rbk-pB-75-B-20,
.rbk-pB-75-B-24,
.rbk-pB-100-N-14,
.rbk-pB-100-N-16,
.rbk-pB-100-N-18,
.rbk-pB-100-N-20,
.rbk-pB-100-N-24,
.rbk-pB-100-M-14,
.rbk-pB-100-M-16,
.rbk-pB-100-M-18,
.rbk-pB-100-M-20,
.rbk-pB-100-M-24,
.rbk-pB-100-SB-14,
.rbk-pB-100-SB-16,
.rbk-pB-100-SB-18,
.rbk-pB-100-SB-20,
.rbk-pB-100-SB-24,
.rbk-pB-100-B-14,
.rbk-pB-100-B-16,
.rbk-pB-100-B-18,
.rbk-pB-100-B-20,
.rbk-pB-100-B-24,
.rbk-g-75-N-14,
.rbk-g-75-N-16,
.rbk-g-75-N-18,
.rbk-g-75-N-20,
.rbk-g-75-N-24,
.rbk-g-75-M-14,
.rbk-g-75-M-16,
.rbk-g-75-M-18,
.rbk-g-75-M-20,
.rbk-g-75-M-24,
.rbk-g-75-SB-14,
.rbk-g-75-SB-16,
.rbk-g-75-SB-18,
.rbk-g-75-SB-20,
.rbk-g-75-SB-24,
.rbk-g-75-B-14,
.rbk-g-75-B-16,
.rbk-g-75-B-18,
.rbk-g-75-B-20,
.rbk-g-75-B-24,
.rbk-g-100-N-14,
.rbk-g-100-N-16,
.rbk-g-100-N-18,
.rbk-g-100-N-20,
.rbk-g-100-N-24,
.rbk-g-100-M-14,
.rbk-g-100-M-16,
.rbk-g-100-M-18,
.rbk-g-100-M-20,
.rbk-g-100-M-24,
.rbk-g-100-SB-14,
.rbk-g-100-SB-16,
.rbk-g-100-SB-18,
.rbk-g-100-SB-20,
.rbk-g-100-SB-24,
.rbk-g-100-B-14,
.rbk-g-100-B-16,
.rbk-g-100-B-18,
.rbk-g-100-B-20,
.rbk-g-100-B-24 {
  font-family: Rubik;
}
.int-pB-75-N-14,
.int-pB-75-N-16,
.int-pB-75-N-18,
.int-pB-75-N-20,
.int-pB-75-N-24,
.int-pB-75-M-14,
.int-pB-75-M-16,
.int-pB-75-M-18,
.int-pB-75-M-20,
.int-pB-75-M-24,
.int-pB-75-SB-14,
.int-pB-75-SB-16,
.int-pB-75-SB-18,
.int-pB-75-SB-20,
.int-pB-75-SB-24,
.int-pB-75-B-14,
.int-pB-75-B-16,
.int-pB-75-B-18,
.int-pB-75-B-20,
.int-pB-75-B-24,
.int-pB-100-N-14,
.int-pB-100-N-16,
.int-pB-100-N-18,
.int-pB-100-N-20,
.int-pB-100-N-24,
.int-pB-100-M-14,
.int-pB-100-M-16,
.int-pB-100-M-18,
.int-pB-100-M-20,
.int-pB-100-M-24,
.int-pB-100-SB-14,
.int-pB-100-SB-16,
.int-pB-100-SB-18,
.int-pB-100-SB-20,
.int-pB-100-SB-24,
.int-pB-100-B-14,
.int-pB-100-B-16,
.int-pB-100-B-18,
.int-pB-100-B-20,
.int-pB-100-B-24,
.int-g-75-N-14,
.int-g-75-N-16,
.int-g-75-N-18,
.int-g-75-N-20,
.int-g-75-N-24,
.int-g-75-M-14,
.int-g-75-M-16,
.int-g-75-M-18,
.int-g-75-M-20,
.int-g-75-M-24,
.int-g-75-SB-14,
.int-g-75-SB-16,
.int-g-75-SB-18,
.int-g-75-SB-20,
.int-g-75-SB-24,
.int-g-75-B-14,
.int-g-75-B-16,
.int-g-75-B-18,
.int-g-75-B-20,
.int-g-75-B-24,
.int-g-100-N-14,
.int-g-100-N-16,
.int-g-100-N-18,
.int-g-100-N-20,
.int-g-100-N-24,
.int-g-100-M-14,
.int-g-100-M-16,
.int-g-100-M-18,
.int-g-100-M-20,
.int-g-100-M-24,
.int-g-100-SB-14,
.int-g-100-SB-16,
.int-g-100-SB-18,
.int-g-100-SB-20,
.int-g-100-SB-24,
.int-g-100-B-14,
.int-g-100-B-16,
.int-g-100-B-18,
.int-g-100-B-20,
.int-g-100-B-24 {
  font-family: Inter;
}
.rbk-pB-75-N-14,
.rbk-pB-75-N-16,
.rbk-pB-75-N-18,
.rbk-pB-75-N-20,
.rbk-pB-75-N-24,
.rbk-pB-75-M-14,
.rbk-pB-75-M-16,
.rbk-pB-75-M-18,
.rbk-pB-75-M-20,
.rbk-pB-75-M-24,
.rbk-pB-75-SB-14,
.rbk-pB-75-SB-16,
.rbk-pB-75-SB-18,
.rbk-pB-75-SB-20,
.rbk-pB-75-SB-24,
.rbk-pB-75-B-14,
.rbk-pB-75-B-16,
.rbk-pB-75-B-18,
.rbk-pB-75-B-20,
.rbk-pB-75-B-24,
.rbk-pB-100-N-14,
.rbk-pB-100-N-16,
.rbk-pB-100-N-18,
.rbk-pB-100-N-20,
.rbk-pB-100-N-24,
.rbk-pB-100-M-14,
.rbk-pB-100-M-16,
.rbk-pB-100-M-18,
.rbk-pB-100-M-20,
.rbk-pB-100-M-24,
.rbk-pB-100-SB-14,
.rbk-pB-100-SB-16,
.rbk-pB-100-SB-18,
.rbk-pB-100-SB-20,
.rbk-pB-100-SB-24,
.rbk-pB-100-B-14,
.rbk-pB-100-B-16,
.rbk-pB-100-B-18,
.rbk-pB-100-B-20,
.rbk-pB-100-B-24,
.int-pB-75-N-14,
.int-pB-75-N-16,
.int-pB-75-N-18,
.int-pB-75-N-20,
.int-pB-75-N-24,
.int-pB-75-M-14,
.int-pB-75-M-16,
.int-pB-75-M-18,
.int-pB-75-M-20,
.int-pB-75-M-24,
.int-pB-75-SB-14,
.int-pB-75-SB-16,
.int-pB-75-SB-18,
.int-pB-75-SB-20,
.int-pB-75-SB-24,
.int-pB-75-B-14,
.int-pB-75-B-16,
.int-pB-75-B-18,
.int-pB-75-B-20,
.int-pB-75-B-24,
.int-pB-100-N-14,
.int-pB-100-N-16,
.int-pB-100-N-18,
.int-pB-100-N-20,
.int-pB-100-N-24,
.int-pB-100-M-14,
.int-pB-100-M-16,
.int-pB-100-M-18,
.int-pB-100-M-20,
.int-pB-100-M-24,
.int-pB-100-SB-14,
.int-pB-100-SB-16,
.int-pB-100-SB-18,
.int-pB-100-SB-20,
.int-pB-100-SB-24,
.int-pB-100-B-14,
.int-pB-100-B-16,
.int-pB-100-B-18,
.int-pB-100-B-20,
.int-pB-100-B-24 {
  color: var(--primaryBlue);
}
.rbk-g-75-N-14,
.rbk-g-75-N-16,
.rbk-g-75-N-18,
.rbk-g-75-N-20,
.rbk-g-75-N-24,
.rbk-g-75-M-14,
.rbk-g-75-M-16,
.rbk-g-75-M-18,
.rbk-g-75-M-20,
.rbk-g-75-M-24,
.rbk-g-75-SB-14,
.rbk-g-75-SB-16,
.rbk-g-75-SB-18,
.rbk-g-75-SB-20,
.rbk-g-75-SB-24,
.rbk-g-75-B-14,
.rbk-g-75-B-16,
.rbk-g-75-B-18,
.rbk-g-75-B-20,
.rbk-g-75-B-24,
.rbk-g-100-N-14,
.rbk-g-100-N-16,
.rbk-g-100-N-18,
.rbk-g-100-N-20,
.rbk-g-100-N-24,
.rbk-g-100-M-14,
.rbk-g-100-M-16,
.rbk-g-100-M-18,
.rbk-g-100-M-20,
.rbk-g-100-M-24,
.rbk-g-100-SB-14,
.rbk-g-100-SB-16,
.rbk-g-100-SB-18,
.rbk-g-100-SB-20,
.rbk-g-100-SB-24,
.rbk-g-100-B-14,
.rbk-g-100-B-16,
.rbk-g-100-B-18,
.rbk-g-100-B-20,
.rbk-g-100-B-24,
.int-g-75-N-14,
.int-g-75-N-16,
.int-g-75-N-18,
.int-g-75-N-20,
.int-g-75-N-24,
.int-g-75-M-14,
.int-g-75-M-16,
.int-g-75-M-18,
.int-g-75-M-20,
.int-g-75-M-24,
.int-g-75-SB-14,
.int-g-75-SB-16,
.int-g-75-SB-18,
.int-g-75-SB-20,
.int-g-75-SB-24,
.int-g-75-B-14,
.int-g-75-B-16,
.int-g-75-B-18,
.int-g-75-B-20,
.int-g-75-B-24,
.int-g-100-N-14,
.int-g-100-N-16,
.int-g-100-N-18,
.int-g-100-N-20,
.int-g-100-N-24,
.int-g-100-M-14,
.int-g-100-M-16,
.int-g-100-M-18,
.int-g-100-M-20,
.int-g-100-M-24,
.int-g-100-SB-14,
.int-g-100-SB-16,
.int-g-100-SB-18,
.int-g-100-SB-20,
.int-g-100-SB-24,
.int-g-100-B-14,
.int-g-100-B-16,
.int-g-100-B-18,
.int-g-100-B-20,
.int-g-100-B-24 {
  color: var(--green);
}
.rbk-pB-75-N-14,
.rbk-pB-75-N-16,
.rbk-pB-75-N-18,
.rbk-pB-75-N-20,
.rbk-pB-75-N-24,
.rbk-pB-75-M-14,
.rbk-pB-75-M-16,
.rbk-pB-75-M-18,
.rbk-pB-75-M-20,
.rbk-pB-75-M-24,
.rbk-pB-75-SB-14,
.rbk-pB-75-SB-16,
.rbk-pB-75-SB-18,
.rbk-pB-75-SB-20,
.rbk-pB-75-SB-24,
.rbk-pB-75-B-14,
.rbk-pB-75-B-16,
.rbk-pB-75-B-18,
.rbk-pB-75-B-20,
.rbk-pB-75-B-24,
.rbk-g-75-N-14,
.rbk-g-75-N-16,
.rbk-g-75-N-18,
.rbk-g-75-N-20,
.rbk-g-75-N-24,
.rbk-g-75-M-14,
.rbk-g-75-M-16,
.rbk-g-75-M-18,
.rbk-g-75-M-20,
.rbk-g-75-M-24,
.rbk-g-75-SB-14,
.rbk-g-75-SB-16,
.rbk-g-75-SB-18,
.rbk-g-75-SB-20,
.rbk-g-75-SB-24,
.rbk-g-75-B-14,
.rbk-g-75-B-16,
.rbk-g-75-B-18,
.rbk-g-75-B-20,
.rbk-g-75-B-24,
.int-pB-75-N-14,
.int-pB-75-N-16,
.int-pB-75-N-18,
.int-pB-75-N-20,
.int-pB-75-N-24,
.int-pB-75-M-14,
.int-pB-75-M-16,
.int-pB-75-M-18,
.int-pB-75-M-20,
.int-pB-75-M-24,
.int-pB-75-SB-14,
.int-pB-75-SB-16,
.int-pB-75-SB-18,
.int-pB-75-SB-20,
.int-pB-75-SB-24,
.int-pB-75-B-14,
.int-pB-75-B-16,
.int-pB-75-B-18,
.int-pB-75-B-20,
.int-pB-75-B-24,
.int-g-75-N-14,
.int-g-75-N-16,
.int-g-75-N-18,
.int-g-75-N-20,
.int-g-75-N-24,
.int-g-75-M-14,
.int-g-75-M-16,
.int-g-75-M-18,
.int-g-75-M-20,
.int-g-75-M-24,
.int-g-75-SB-14,
.int-g-75-SB-16,
.int-g-75-SB-18,
.int-g-75-SB-20,
.int-g-75-SB-24,
.int-g-75-B-14,
.int-g-75-B-16,
.int-g-75-B-18,
.int-g-75-B-20,
.int-g-75-B-24 {
  opacity: 75%;
}
.rbk-pB-100-N-14,
.rbk-pB-100-N-16,
.rbk-pB-100-N-18,
.rbk-pB-100-N-20,
.rbk-pB-100-N-24,
.rbk-pB-100-M-14,
.rbk-pB-100-M-16,
.rbk-pB-100-M-18,
.rbk-pB-100-M-20,
.rbk-pB-100-M-24,
.rbk-pB-100-SB-14,
.rbk-pB-100-SB-16,
.rbk-pB-100-SB-18,
.rbk-pB-100-SB-20,
.rbk-pB-100-SB-24,
.rbk-pB-100-B-14,
.rbk-pB-100-B-16,
.rbk-pB-100-B-18,
.rbk-pB-100-B-20,
.rbk-pB-100-B-24,
.rbk-g-100-N-14,
.rbk-g-100-N-16,
.rbk-g-100-N-18,
.rbk-g-100-N-20,
.rbk-g-100-N-24,
.rbk-g-100-M-14,
.rbk-g-100-M-16,
.rbk-g-100-M-18,
.rbk-g-100-M-20,
.rbk-g-100-M-24,
.rbk-g-100-SB-14,
.rbk-g-100-SB-16,
.rbk-g-100-SB-18,
.rbk-g-100-SB-20,
.rbk-g-100-SB-24,
.rbk-g-100-B-14,
.rbk-g-100-B-16,
.rbk-g-100-B-18,
.rbk-g-100-B-20,
.rbk-g-100-B-24,
.int-pB-100-N-14,
.int-pB-100-N-16,
.int-pB-100-N-18,
.int-pB-100-N-20,
.int-pB-100-N-24,
.int-pB-100-M-14,
.int-pB-100-M-16,
.int-pB-100-M-18,
.int-pB-100-M-20,
.int-pB-100-M-24,
.int-pB-100-SB-14,
.int-pB-100-SB-16,
.int-pB-100-SB-18,
.int-pB-100-SB-20,
.int-pB-100-SB-24,
.int-pB-100-B-14,
.int-pB-100-B-16,
.int-pB-100-B-18,
.int-pB-100-B-20,
.int-pB-100-B-24,
.int-g-100-N-14,
.int-g-100-N-16,
.int-g-100-N-18,
.int-g-100-N-20,
.int-g-100-N-24,
.int-g-100-M-14,
.int-g-100-M-16,
.int-g-100-M-18,
.int-g-100-M-20,
.int-g-100-M-24,
.int-g-100-SB-14,
.int-g-100-SB-16,
.int-g-100-SB-18,
.int-g-100-SB-20,
.int-g-100-SB-24,
.int-g-100-B-14,
.int-g-100-B-16,
.int-g-100-B-18,
.int-g-100-B-20,
.int-g-100-B-24 {
  opacity: 100%;
}
.rbk-pB-75-N-14,
.rbk-pB-75-N-16,
.rbk-pB-75-N-18,
.rbk-pB-75-N-20,
.rbk-pB-75-N-24,
.rbk-pB-100-N-14,
.rbk-pB-100-N-16,
.rbk-pB-100-N-18,
.rbk-pB-100-N-20,
.rbk-pB-100-N-24,
.rbk-g-75-N-14,
.rbk-g-75-N-16,
.rbk-g-75-N-18,
.rbk-g-75-N-20,
.rbk-g-75-N-24,
.rbk-g-100-N-14,
.rbk-g-100-N-16,
.rbk-g-100-N-18,
.rbk-g-100-N-20,
.rbk-g-100-N-24,
.int-pB-75-N-14,
.int-pB-75-N-16,
.int-pB-75-N-18,
.int-pB-75-N-20,
.int-pB-75-N-24,
.int-pB-100-N-14,
.int-pB-100-N-16,
.int-pB-100-N-18,
.int-pB-100-N-20,
.int-pB-100-N-24,
.int-g-75-N-14,
.int-g-75-N-16,
.int-g-75-N-18,
.int-g-75-N-20,
.int-g-75-N-24,
.int-g-100-N-14,
.int-g-100-N-16,
.int-g-100-N-18,
.int-g-100-N-20,
.int-g-100-N-24 {
  font-weight: 400;
}
.rbk-pB-75-M-14,
.rbk-pB-75-M-16,
.rbk-pB-75-M-18,
.rbk-pB-75-M-20,
.rbk-pB-75-M-24,
.rbk-pB-100-M-14,
.rbk-pB-100-M-16,
.rbk-pB-100-M-18,
.rbk-pB-100-M-20,
.rbk-pB-100-M-24,
.rbk-g-75-M-14,
.rbk-g-75-M-16,
.rbk-g-75-M-18,
.rbk-g-75-M-20,
.rbk-g-75-M-24,
.rbk-g-100-M-14,
.rbk-g-100-M-16,
.rbk-g-100-M-18,
.rbk-g-100-M-20,
.rbk-g-100-M-24,
.int-pB-75-M-14,
.int-pB-75-M-16,
.int-pB-75-M-18,
.int-pB-75-M-20,
.int-pB-75-M-24,
.int-pB-100-M-14,
.int-pB-100-M-16,
.int-pB-100-M-18,
.int-pB-100-M-20,
.int-pB-100-M-24,
.int-g-75-M-14,
.int-g-75-M-16,
.int-g-75-M-18,
.int-g-75-M-20,
.int-g-75-M-24,
.int-g-100-M-14,
.int-g-100-M-16,
.int-g-100-M-18,
.int-g-100-M-20,
.int-g-100-M-24 {
  font-weight: 500;
}
.rbk-pB-75-SB-14,
.rbk-pB-75-SB-16,
.rbk-pB-75-SB-18,
.rbk-pB-75-SB-20,
.rbk-pB-75-SB-24,
.rbk-pB-100-SB-14,
.rbk-pB-100-SB-16,
.rbk-pB-100-SB-18,
.rbk-pB-100-SB-20,
.rbk-pB-100-SB-24,
.rbk-g-75-SB-14,
.rbk-g-75-SB-16,
.rbk-g-75-SB-18,
.rbk-g-75-SB-20,
.rbk-g-75-SB-24,
.rbk-g-100-SB-14,
.rbk-g-100-SB-16,
.rbk-g-100-SB-18,
.rbk-g-100-SB-20,
.rbk-g-100-SB-24,
.int-pB-75-SB-14,
.int-pB-75-SB-16,
.int-pB-75-SB-18,
.int-pB-75-SB-20,
.int-pB-75-SB-24,
.int-pB-100-SB-14,
.int-pB-100-SB-16,
.int-pB-100-SB-18,
.int-pB-100-SB-20,
.int-pB-100-SB-24,
.int-g-75-SB-14,
.int-g-75-SB-16,
.int-g-75-SB-18,
.int-g-75-SB-20,
.int-g-75-SB-24,
.int-g-100-SB-14,
.int-g-100-SB-16,
.int-g-100-SB-18,
.int-g-100-SB-20,
.int-g-100-SB-24 {
  font-weight: 600;
}
.rbk-pB-75-B-14,
.rbk-pB-75-B-16,
.rbk-pB-75-B-18,
.rbk-pB-75-B-20,
.rbk-pB-75-B-24,
.rbk-pB-100-B-14,
.rbk-pB-100-B-16,
.rbk-pB-100-B-18,
.rbk-pB-100-B-20,
.rbk-pB-100-B-24,
.rbk-g-75-B-14,
.rbk-g-75-B-16,
.rbk-g-75-B-18,
.rbk-g-75-B-20,
.rbk-g-75-B-24,
.rbk-g-100-B-14,
.rbk-g-100-B-16,
.rbk-g-100-B-18,
.rbk-g-100-B-20,
.rbk-g-100-B-24,
.int-pB-75-B-14,
.int-pB-75-B-16,
.int-pB-75-B-18,
.int-pB-75-B-20,
.int-pB-75-B-24,
.int-pB-100-B-14,
.int-pB-100-B-16,
.int-pB-100-B-18,
.int-pB-100-B-20,
.int-pB-100-B-24,
.int-g-75-B-14,
.int-g-75-B-16,
.int-g-75-B-18,
.int-g-75-B-20,
.int-g-75-B-24,
.int-g-100-B-14,
.int-g-100-B-16,
.int-g-100-B-18,
.int-g-100-B-20,
.int-g-100-B-24 {
  font-weight: 700;
}
.rbk-pB-75-N-14,
.rbk-pB-75-M-14,
.rbk-pB-75-SB-14,
.rbk-pB-75-B-14,
.rbk-pB-100-N-14,
.rbk-pB-100-M-14,
.rbk-pB-100-SB-14,
.rbk-pB-100-B-14,
.rbk-g-75-N-14,
.rbk-g-75-M-14,
.rbk-g-75-SB-14,
.rbk-g-75-B-14,
.rbk-g-100-N-14,
.rbk-g-100-M-14,
.rbk-g-100-SB-14,
.rbk-g-100-B-14,
.int-pB-75-N-14,
.int-pB-75-M-14,
.int-pB-75-SB-14,
.int-pB-75-B-14,
.int-pB-100-N-14,
.int-pB-100-M-14,
.int-pB-100-SB-14,
.int-pB-100-B-14,
.int-g-75-N-14,
.int-g-75-M-14,
.int-g-75-SB-14,
.int-g-75-B-14,
.int-g-100-N-14,
.int-g-100-M-14,
.int-g-100-SB-14,
.int-g-100-B-14 {
  font-size: 14px;
}
.rbk-pB-75-N-16,
.rbk-pB-75-M-16,
.rbk-pB-75-SB-16,
.rbk-pB-75-B-16,
.rbk-pB-100-N-16,
.rbk-pB-100-M-16,
.rbk-pB-100-SB-16,
.rbk-pB-100-B-16,
.rbk-g-75-N-16,
.rbk-g-75-M-16,
.rbk-g-75-SB-16,
.rbk-g-75-B-16,
.rbk-g-100-N-16,
.rbk-g-100-M-16,
.rbk-g-100-SB-16,
.rbk-g-100-B-16,
.int-pB-75-N-16,
.int-pB-75-M-16,
.int-pB-75-SB-16,
.int-pB-75-B-16,
.int-pB-100-N-16,
.int-pB-100-M-16,
.int-pB-100-SB-16,
.int-pB-100-B-16,
.int-g-75-N-16,
.int-g-75-M-16,
.int-g-75-SB-16,
.int-g-75-B-16,
.int-g-100-N-16,
.int-g-100-M-16,
.int-g-100-SB-16,
.int-g-100-B-16 {
  font-size: 16px;
}
.rbk-pB-75-N-18,
.rbk-pB-75-M-18,
.rbk-pB-75-SB-18,
.rbk-pB-75-B-18,
.rbk-pB-100-N-18,
.rbk-pB-100-M-18,
.rbk-pB-100-SB-18,
.rbk-pB-100-B-18,
.rbk-g-75-N-18,
.rbk-g-75-M-18,
.rbk-g-75-SB-18,
.rbk-g-75-B-18,
.rbk-g-100-N-18,
.rbk-g-100-M-18,
.rbk-g-100-SB-18,
.rbk-g-100-B-18,
.int-pB-75-N-18,
.int-pB-75-M-18,
.int-pB-75-SB-18,
.int-pB-75-B-18,
.int-pB-100-N-18,
.int-pB-100-M-18,
.int-pB-100-SB-18,
.int-pB-100-B-18,
.int-g-75-N-18,
.int-g-75-M-18,
.int-g-75-SB-18,
.int-g-75-B-18,
.int-g-100-N-18,
.int-g-100-M-18,
.int-g-100-SB-18,
.int-g-100-B-18 {
  font-size: 18px;
}
.rbk-pB-75-N-20,
.rbk-pB-75-M-20,
.rbk-pB-75-SB-20,
.rbk-pB-75-B-20,
.rbk-pB-100-N-20,
.rbk-pB-100-M-20,
.rbk-pB-100-SB-20,
.rbk-pB-100-B-20,
.rbk-g-75-N-20,
.rbk-g-75-M-20,
.rbk-g-75-SB-20,
.rbk-g-75-B-20,
.rbk-g-100-N-20,
.rbk-g-100-M-20,
.rbk-g-100-SB-20,
.rbk-g-100-B-20,
.int-pB-75-N-20,
.int-pB-75-M-20,
.int-pB-75-SB-20,
.int-pB-75-B-20,
.int-pB-100-N-20,
.int-pB-100-M-20,
.int-pB-100-SB-20,
.int-pB-100-B-20,
.int-g-75-N-20,
.int-g-75-M-20,
.int-g-75-SB-20,
.int-g-75-B-20,
.int-g-100-N-20,
.int-g-100-M-20,
.int-g-100-SB-20,
.int-g-100-B-20 {
  font-size: 20px;
}
.rbk-pB-75-N-24,
.rbk-pB-75-M-24,
.rbk-pB-75-SB-24,
.rbk-pB-75-B-24,
.rbk-pB-100-N-24,
.rbk-pB-100-M-24,
.rbk-pB-100-SB-24,
.rbk-pB-100-B-24,
.rbk-g-75-N-24,
.rbk-g-75-M-24,
.rbk-g-75-SB-24,
.rbk-g-75-B-24,
.rbk-g-100-N-24,
.rbk-g-100-M-24,
.rbk-g-100-SB-24,
.rbk-g-100-B-24,
.int-pB-75-N-24,
.int-pB-75-M-24,
.int-pB-75-SB-24,
.int-pB-75-B-24,
.int-pB-100-N-24,
.int-pB-100-M-24,
.int-pB-100-SB-24,
.int-pB-100-B-24,
.int-g-75-N-24,
.int-g-75-M-24,
.int-g-75-SB-24,
.int-g-75-B-24,
.int-g-100-N-24,
.int-g-100-M-24,
.int-g-100-SB-24,
.int-g-100-B-24 {
  font-size: 24px;
}
